import {Route, Redirect, Switch} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'
import { AuthRequired } from "../../PushtradePages/authRequired";

const Login = lazy(() => import('../../PushtradePages/Login'));
const Logout = lazy(() => import('../../PushtradePages/Logout'));

const Clients = lazy(() => import('../../PushtradePages/Clients'));
const Portfolios = lazy(() => import('../../PushtradePages/Portfolios'));
const TradeItems = lazy(() => import('../../PushtradePages/TradeItems'));
const Stocks = lazy(() => import('../../PushtradePages/Stocks'));
const Orders = lazy(() => import('../../PushtradePages/Orders'));
const PushtradeLog = lazy(() => import('../../PushtradePages/PushtradeLog'));
const MobileVerify = lazy(() => import('../../PushtradePages/ClientPages/mobileVerify'));
const PushtradeSms = lazy(() => import('../../PushtradePages/ClientPages/pushtradeSms'));

const AppMain = () => {

    return (
        <Fragment>

            {/* Pushtrade */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Loading...
                        </h6>
                    </div>
                </div>
            }>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>

                    <Route exact path="/clients/profile" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<Clients match={{ url: "/clients" }} />}
                    />)}/>
                    <Route exact path="/clients" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<Clients match={{ url: "/clients" }} />}
                    />)}/>

                    <Route exact path="/portfolios" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<Portfolios match={{ url: "/portfolios" }} />}
                    />)}/>
                    <Route exact path="/portfolios/content" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<TradeItems match={{ url: "/portfolios" }} />}
                    />)}/>

                    <Route exact path="/stocks" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<Stocks match={{ url: "/stocks" }} />}
                    />)}/>

                    <Route exact path="/orders" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<Orders match={{ url: "/orders" }} />}
                    />)}/>

                    <Route exact path="/pushtrade-log" render={() => (
                        <AuthRequired redirectTo='/login' orRender={<PushtradeLog match={{ url: "/pushtrade-log" }} />}
                    />)}/>

                    <Route path="/mobileverify/:id" component={MobileVerify}/>

                    <Route path="/pushtradesms/:id" component={PushtradeSms}/>

                </Switch>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/login"/>
            )}/>

        </Fragment>
    )
};

export default AppMain;
