import Configuration from './configuration';

class PushtradeApiClient {
    constructor() {
        this.config = new Configuration();
    }

    async request(method, action, body, mustAuth, params=null) {
        let token = localStorage.getItem('token');
        if (mustAuth && token == null)
            return {error: true, response: `No mustAuth && no token`};
            // return null;

        let headers = { "Accept": "application/json", "Content-Type": "application/json" };
        
        if (mustAuth)
            headers.Authorization = 'Bearer ' + token;

        let fetchUrl = this.config.PUSHTRADE_API_URL + action;

        if(params) {
            let queryParams = new URLSearchParams();
            Object.entries(params).forEach(([key, value]) => {
                // console.log(`APPEND key=${key}, value=${value}`);
                queryParams.append(key, value);
            });
            fetchUrl += `?${queryParams.toString()}`;
        }

        return fetch(fetchUrl , {
                method: method,
                headers: headers,
                body: body == null ? null : JSON.stringify(body)
                })
            .then(async response => {
                if (!response.ok) {
                    // console.log(`response.status = ${response.status}`)
                    if (response.status && response.status === 401) { // Unauthorized
                        localStorage.removeItem('token');
                        return {error: true, status: response.status, response: `Status 401: Unauthorized`};
                    }

                    try {
                        const text = await response.text(); // Parse it as text
                        const data = JSON.parse(text); // Try to parse it as JSON
                        return {error: true, status: response.status, response: data};
                    } catch(err) {
                        // console.log(`Error parsing response: ${err}`);
                        return {error: true, response: `No response. err: ${err}`};
                    }
                }

                try {
                    const text = await response.text(); // Parse it as text
                    // console.log(`ALL OK. TEXT = : ${text}`);
                    if (text){
                        const data = JSON.parse(text); // Try to parse it as JSON
                        // console.log(`ALL OK. DATA = : ${data}`);
                        return data;
                    }
                    // console.log(`Text empty? return 'text' anyway : ${text}`);
                    return text;
                } catch(err) {
                    // console.log(`Error parsing response: ${err}`);
                    return {error: true, response: `No response. err: ${err}`};
                    // return err;
                }

                // este return en la mitad de los casos se rompe:
                // return response.json();
            });
    }

    async get(action, params = null, mustAuth = true) {
        
        console.log(`GET action=${action}, params=${params}, mustAuth=${mustAuth}`);
        return this.request('GET', action, null, mustAuth, params);
    }

    async post(action, body, mustAuth = true) {
        return this.request('POST', action, body, mustAuth);
    }

    async put(action, body, mustAuth = true) {
        return this.request('PUT', action, body, mustAuth);
    }

    async delete(action, body, mustAuth = true) {
        return this.request('DELETE', action, body, mustAuth);
    }
}

export default PushtradeApiClient;
