class Configuration {
    
    PUSHTRADE_API_URL = "https://api-pushtrade.rivkin.com.au/api/";

    // we'll store the client's configuration here for now, should be pulled from the pushtrade_configurations table instead

    B2B_PARTNER_ID = 1; // 1 = Rivkin, 2 = AKBank, 3 = QNB
    B2B_PARTNER_LANGUAGE = 'en'; // valid values: en (for rivkin), tr2 (for akbank), tr3 (for qnb)
    
    // features configuration for Rivkin
    FEATURE_ENABLED_PORTFOLIO_CURRENCY = false;
    FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY = true;
    FEATURE_ENABLED_CANCEL_ORDER = false;
    FEATURE_ENABLED_CANCEL_RECOMMENDATION = false;
    FEATURE_ENABLED_PENDING_TAB = true;
    FEATURE_ENABLED_SL_TP = false;
    FEATURE_ENABLED_PASSWORD_ON_SUBMIT = false;
}
export default Configuration;
